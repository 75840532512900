import { useEffect, useState } from "react";
import { isMobile as isMobileDetect } from "react-device-detect";

import { StyleSheet, css } from "aphrodite";
import { useRouter } from "next/router";
import { PROGRESSBAR_ROUTING } from "/config/PageRouting";
import { useExperiment } from "../../../../contexts/ExperimentContext";
import ProgressBar from "../molecules/ProgressBar";
import styles from "./Panel.module.scss";

export default function Panel({
  children,
  className,
  panelContainerClassName,
  large,
  showProgressBar,
  progressType,
  currentStep,
  step,
}) {
  const { experiment } = useExperiment();
  const router = useRouter();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  const classNames = `${styles.Panel} ${className ? className : ""} ${
    large ? styles["--large"] : ""
  } ${showProgressBar ? styles["--progress-bar"] : ""} ${
    currentStep ? styles["--large-new"] : ""
  }`.trim();
  const steps = PROGRESSBAR_ROUTING(experiment)[progressType] || [];

  return (
    <div className={classNames}>
      <div className={css(aphStyles.progressContainer)}>
        {showProgressBar && (
          <ProgressBar maxSteps={steps.length} currentStep={currentStep} />
        )}
      </div>
      <div
        className={[
          styles.Panel__container,
          styles["--container-new"],
          { timeframe: true, mortgage: true, property: true }[router.query.step]
            ? styles.stepComponentLarger
            : "",
          step === "rocket" ? styles["--container-rocket"] : "",
          panelContainerClassName,
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
}

const aphStyles = StyleSheet.create({
  desktop: {
    "@media only screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  progressContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});
